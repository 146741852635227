import type { ListingData } from "./listings/new";
import type { Organization } from "./listings/organization";
import type { AdditionalUser, User as ListingDataUser } from "./listings/user";
import type { ProcessedListingAddress, User } from "./sharedSchema";

export type BaseDocument = {
  id: string;
  path?: string;
  createdTime: Date;
  updatedTime: Date;
};

export enum CrmType {
  realEstate = "realEstate",
  generic = "generic"
}

export enum AutoReplyBotTier {
  free = "free",
  paid_shared = "paid_shared",
  paid_dedicated = "paid_dedicated"
}

export const OnboardingSteps = [
  "phoneNumber",
  "userRolePick",
  "realEstateRolePick",
  "featuresPicker",
  "test"
] as const;
export type OnboardingStep = typeof OnboardingSteps[number];

export type UserSettings = User;

export type BotDefinitionStartNode = {
  id: string;
  match: string[];
  matchType: string;
  name: string;
  response: {
    mediaLink: string;
    text: string;
  };
  status: string;
  actions: any[];
};

export type AutoReplyBotSettings = {
  botDefinition: BotDefinitionStartNode[];
  ownerAlias: string | null;
  ownerFullName: string | null;
  ownerShortName: string | null;
  ownerMail: string | null;
  ownerPhoneNumber: string | null;
  tier: AutoReplyBotTier | null;
  whatsappPhoneNumber: string | null;
  customerWebhook: string | null;
};

export type BotTriggerLead = {
  fromNumberInternational: string;
};

export type FirestoreContextProps = {
  myVirtualNumbers: Array<any> | null; // todo remove
  callsByVirtualNumber: Map<string, any> | null;
  setShouldFetchAllCallsByVirtualNumber: (boolean) => void;
  myApiToken: string | null; // todo remove
  featureFlags: any; // todo remove
  userSettings: UserSettings;
  autoReplyBotSettings: AutoReplyBotSettings;
  hasAccessToOtherUsers: string[];
  listingData: null | ListingData;
  isLoadingUser: boolean;
  isLoadingListingData: boolean;
  updateUserSettings: (data: Partial<UserSettings>) => Promise<void>;
  updateUserListingData: (data: Partial<ListingDataUser>) => Promise<void>;
  saveSentMessage: (Object) => Promise<void>;
  messagesSentCount: number; // partial, will fetch up to MAX_MESSAGES_IN_FREE_TRIAL
  organization: null | undefined | Organization;
  organizations: Organization[];
  organizationId: string | null;
  organizationUsers: AdditionalUser[];
  isLoadingOrganization: boolean;
  isAdminOfTheOrganization: boolean;
  changeOrganization: (organizationId: string) => void;
};
