import Label from "components/Label";
import { Stack } from "@material-ui/core";
import { getListingAddressString } from "utils/Haim/utils";
import type { RealEstateContact } from "../../../../../@types/sharedSchema";

export type ValidPropertyType = keyof Pick<
  RealEstateContact,
  "interestedInLocations" | "interestedInPropertyTypes"
>;

type ContactTablePropertyProps = {
  type: ValidPropertyType;
  contact: Partial<RealEstateContact>;
};

export function ContactTableProperty({ type, contact }: ContactTablePropertyProps): JSX.Element {
  const propertyArray = contact[type];

  if (!propertyArray?.length) return null;

  const isLocationsData = type === "interestedInLocations";

  const labelColor = isLocationsData ? "success" : "info";

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {propertyArray.map((item, index) => (
        <Label color={labelColor} key={index}>
          {isLocationsData ? getListingAddressString(item) : item}
        </Label>
      ))}
    </Stack>
  );
}
