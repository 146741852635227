import { Dialog } from "@material-ui/core";
import { CalendarEvent } from "../../../@types/sharedSchema";
import { CalendarForm } from "components/_dashboard/calendar";
import type { DialogProps } from "@material-ui/core/Dialog";

type Props = DialogProps & {
  event: CalendarEvent;
  createOrUpdateEvent: (event: CalendarEvent) => Promise<boolean>;
  deleteEvent: (eventId: string) => Promise<void>;
};

export const CalendarEventDialog: React.FC<Props> = ({
  open,
  event,
  onClose,
  deleteEvent,
  createOrUpdateEvent,
  ...props
}) => {
  return (
    <Dialog {...props} open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <CalendarForm
        event={event}
        onCancel={onClose as VoidFunction}
        onUpdate={createOrUpdateEvent}
        onDelete={deleteEvent}
      />
    </Dialog>
  );
};
