import {
  Input,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Avatar
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import { useTheme } from "@material-ui/core/styles";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { PhoneNumber } from "libphonenumber-js/max";
import NumberFormat from "react-number-format";
import Label from "../../components/Label";
import useLocales from "../../hooks/useLocales";
import usePrevious from "hooks/usePrevious";
import SendStatusCell from "../../components/__haim/WhatsAppWebSendMessages/SendMessagesStep/SendStatusCell";
import { parsePhoneNumber } from "./phoneNumbersUtils";
import CallStatusCell from "../../components/__haim/Common/CallStatusCell";
import Slider from "@material-ui/core/Slider";
import { Box } from "@material-ui/system";
import moment from "moment-timezone";
import "moment/locale/he"; // without this line, language is not imported
import MoreMenu from "components/__haim/Common/MoreMenu";
import LoadingScreen from "components/LoadingScreen";
import useApp from "hooks/useApp";
import { ContactTableDateTime } from "components/_dashboard/contacts/table/rows/ContactTableDateTime";
import { ContactTableProperty } from "components/_dashboard/contacts/table/rows/ContactTableProperty";

const onKeyDownIgnoreNavigation = (event) => {
  const { keyCode } = event;
  const KEY_LEFT = 37;
  const KEY_UP = 38;
  const KEY_RIGHT = 39;
  const KEY_DOWN = 40;
  const KEY_PAGE_UP = 33;
  const KEY_PAGE_DOWN = 34;
  const KEY_PAGE_HOME = 36;
  const KEY_PAGE_END = 35;

  const isNavigationKey =
    keyCode === KEY_LEFT ||
    keyCode === KEY_RIGHT ||
    keyCode === KEY_UP ||
    keyCode === KEY_DOWN ||
    keyCode === KEY_PAGE_DOWN ||
    keyCode === KEY_PAGE_UP ||
    keyCode === KEY_PAGE_HOME ||
    keyCode === KEY_PAGE_END;

  if (isNavigationKey) {
    // this stops the grid from receiving the event and executing keyboard navigation
    event.stopPropagation();
  }
};

// Available colors = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
const ColoredLabelRenderer = (props: any) => {
  const theme = useTheme();

  const isLight = theme.palette.mode === "light";

  if (!props.value) {
    return <div />;
  }
  const color = props.colors ? props.colors[props.value] : "warning";

  return (
    <Label
      variant={isLight ? "ghost" : "filled"}
      color={color}
      sx={{ textTransform: "capitalize", mx: "auto" }}
    >
      {props.value}
    </Label>
  );
};

const InterestedInLocationsRenderer = (props) => {
  const interestedInLocations = props?.data?.interestedInLocations;

  if (!interestedInLocations?.length) return null;

  return (
    <Box sx={{ py: 1 }}>
      <ContactTableProperty type="interestedInLocations" contact={{ interestedInLocations }} />
    </Box>
  );
};

const DateTimeRenderer = (props: any) => {
  const { value } = props;

  if (!value) return "?";

  return <ContactTableDateTime forceHeLocale value={value} />;
};

const StatusCellRenderer = (props: any) => {
  return <SendStatusCell status={props.data.status} />;
};
const BooleanRenderer = (props: any) => {
  const { translate } = useLocales();
  const theme = useTheme();

  const isLight = theme.palette.mode === "light";

  const booleanToYesNo = (value: boolean) =>
    value ? translate("general.yes") : translate("general.no");

  if (props.value === null) {
    return <div />;
  }
  const trueColor = props.colors?.true ?? "success";
  const falseColor = props.colors?.false ?? "warning";

  return (
    <Label
      variant={isLight ? "ghost" : "filled"}
      color={props.value ? trueColor : falseColor}
      sx={{ textTransform: "capitalize", mx: "auto" }}
    >
      {booleanToYesNo(props.value)}
    </Label>
  );
};

const AutoCompleteEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState(props?.value?.toString() ?? "");
  const refInput = useRef(null);

  useEffect(() => {
    // focus on the input
    setTimeout(() => refInput && refInput.current && refInput.current.focus());
  }, []);

  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return value;
    }
  }));

  const onKeyDown = (event) => {
    onKeyDownIgnoreNavigation(event);
  };

  return (
    <select name="pets" id="pet-select" multiple>
      <option value=""> </option>
      <option value="dog">Dog</option>
      <option value="cat">Cat</option>
      <option value="hamster">Hamster</option>
      <option value="parrot">Parrot</option>
      <option value="spider">Spider</option>
      <option value="goldfish">Goldfish</option>
    </select>
    // <Autocomplete
    //   fullWidth
    //   multiple
    //   freeSolo
    //   ref={refInput}
    //   sx={{ display: 'flex', alignItems: 'end' }}
    //   options={Array.from(new Set(['קונה', 'מוכר'].concat(props.possibleValues ?? [])))}
    //   size="medium"
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       variant="standard"
    //       sx={{ display: 'flex', alignItems: 'end' }}
    //       InputProps={{ ...props.TextFieldInputProps, type: 'search' }}
    //     />
    //   )}
    //   onChange={(event: object, value: any, reason: string) => setValue(value)}
    //   onKeyDown={(event) => onKeyDown(event)}
    // />
    // <TextField
    //   ref={refInput}
    //   value={value}
    //   onChange={(event) => setValue(event.target.value)}
    //   variant="standard"
    //   sx={{ display: 'flex', alignItems: 'end' }} // width: "100%"
    //   InputProps={{ ...props.TextFieldInputProps }}
    // />
    // <input type="number"
    //        ref={refInput}
    //        value={value}
    //        onChange={event => setValue(event.target.value)}
    //        style={{width: "100%"}}
    // />
  );
});

const NumberFormatEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState(props?.value);
  const refInput = useRef(null);

  useEffect(() => {
    // focus on the input - causes an error
    console.log(refInput.current);
    // setTimeout(() => refInput && refInput.current && refInput.current.focus());
  }, []);

  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return value;
    }
  }));

  // const onKeyDown = (event) => {
  //   onKeyDownIgnoreNavigation(event);
  // };
  const CustomTextField = (props: any) => (
    <Input {...props} />
    // <Input disableUnderline={true} {...props} />
  );

  return (
    <NumberFormat
      // tabIndex={0}
      ref={refInput}
      value={value}
      thousandSeparator=","
      suffix="₪"
      // autoFocus
      // onBlur={(event: any) => handleBudgetChange(data.id, event)}
      onValueChange={(values: any) => {
        setValue(values.value);
      }}
      type="tel"
      displayType="input"
      isNumericString={true}
      allowNegative={false}
      customInput={CustomTextField}
      renderText={(value: any) => <TextField>{value}</TextField>}
    />
  );
});

const MultiLineRenderer = (props: any) => {
  return <Typography variant="body2">{props.value}</Typography>;
};

const AudioPlayerCellRenderer = (props: any) => {
  return props.value ? (
    <audio src={props.value} controls style={{ zoom: "80%", display: "flex" }} />
  ) : null;
};

function phoneNumberNationalFormatter(params) {
  console.log("Debug here", params, params.value);
  if (!params.value) {
    return null;
  }

  return parsePhoneNumber(params.value.toString())?.formatNational();
}

function currencyFormatter(params) {
  if (params.value === null || params.value === undefined || params.value === "") {
    return null;
  }
  let formatted = formatNumber(params.value);
  return formatted == "NaN" ? null : formatted + " ₪";
}

function formatNumber(number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const CallStatusRenderer = (props: any) => {
  return <CallStatusCell result={props.data.result} />;
};

export const BudgetFilter = forwardRef((props, ref) => {
  // @ts-ignore
  const { filterChangedCallback } = props;
  const [colVals, setColVals] = useState([0, 0]);
  const [selectedMin, setSelectedMin] = useState(0);
  const [selectedMax, setSelectedMax] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [includeNoBudget, setIncludeNoBudget] = useState(true);
  const [stepVal, setStepVal] = useState(25000);
  const { getAllContacts } = useApp();
  const allContacts = getAllContacts();
  const prevMin = usePrevious(minVal);
  const prevMax = usePrevious(maxVal);

  const handleChange = (event, newValue) => {
    setSelectedMin(newValue[0]);
    setSelectedMax(newValue[1]);
  };
  const handleIncludeBudgetChange = () => {
    setIncludeNoBudget((val) => !val);
  };

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        let value = params.data?.customerBudget;
        return includeNoBudget
          ? !value || (value >= selectedMin && value <= selectedMax)
          : !!value && value >= selectedMin && value <= selectedMax;
      },

      isFilterActive() {
        return selectedMin || selectedMax || !includeNoBudget;
      }
    };
  });

  useEffect(() => {
    if (!allContacts) {
      return;
    }
    setColVals(
      allContacts
        .map((contact) => Number(contact.customerBudget))
        .filter((val) => Number.isInteger(val))
    );
  }, [allContacts]);

  useEffect(() => {
    setMinVal(() => Math.floor(Math.min.apply(null, colVals) / stepVal) * stepVal);
    setMaxVal(() => Math.ceil(Math.max.apply(null, colVals) / stepVal) * stepVal);
  }, [colVals]);

  useEffect(() => {
    if (!selectedMin || selectedMin < prevMin + stepVal) {
      setSelectedMin(minVal >= 0 ? minVal : 0);
    }
    if (!selectedMax || selectedMax > prevMax - stepVal) {
      setSelectedMax(maxVal);
    }
  }, [minVal, maxVal]);

  useEffect(() => {
    setTimeout(() => {
      filterChangedCallback();
    }, 0);
  }, [selectedMin, selectedMax, includeNoBudget]);

  return (
    <Box sx={{ width: { xs: "70vw", sm: "400px" }, p: { sm: 2, xs: 1 } }}>
      <Typography align="center">טווח מחירים</Typography>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h4" sx={{ px: 2, lineHeight: { sm: 1, xs: 2 } }}>
          -
        </Typography>

        <Slider
          orientation="horizontal"
          onChange={handleChange}
          min={minVal}
          max={maxVal}
          step={stepVal}
          value={[selectedMin, selectedMax]}
          valueLabelFormat={(value) => currencyFormatter({ value: value })}
          valueLabelDisplay="auto"
        />

        <Typography variant="h4" sx={{ px: 2, lineHeight: { sm: 1, xs: 2 } }}>
          +
        </Typography>
      </Box>

      <p style={{ textAlign: "center", direction: "ltr", fontSize: "1rem" }}>
        {currencyFormatter({ value: selectedMax ? selectedMax : maxVal })} -{" "}
        {currencyFormatter({ value: selectedMin ? selectedMin : minVal })}
      </p>

      <FormControlLabel
        sx={{ display: "flex", justifyContent: "center" }}
        control={
          <Checkbox
            value={includeNoBudget}
            checked={includeNoBudget}
            onChange={handleIncludeBudgetChange}
          />
        }
        label="כלול שורות ללא תקציב"
      />

      <Button
        onClick={() => {
          setIncludeNoBudget(true);
          setSelectedMin(minVal);
          setSelectedMax(maxVal);
        }}
        sx={{ display: "block", mx: "auto" }}
      >
        נקה
      </Button>
    </Box>
  );
});
export const lastBulkMessageReceivedFilter = forwardRef((props, ref) => {
  // @ts-ignore
  const { filterChangedCallback } = props;
  const [dateRange, setDateRange] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  const [dateRangeOption, setDateRangeOption] = useState("");

  const handleDateRangeChange = (event: React.MouseEvent<HTMLInputElement>) => {
    setDateRangeOption((event.target as HTMLTextAreaElement).value as string);
    setFilterActive(() => (((event.target as HTMLTextAreaElement).value as string) ? true : false));
  };

  useEffect(() => {
    switch (dateRangeOption) {
      case "week":
        setDateRange(moment().subtract(7, "d").unix());
        break;
      case "month":
        setDateRange(moment().subtract(30, "d").unix());
        break;
      case "never":
        setDateRange(0);
        break;
      default:
        break;
    }
  }, [dateRangeOption]);

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        return dateRange > 0
          ? !params.data.lastBulkMessageReceived?.sentTime ||
              (params.data.lastBulkMessageReceived?.sentTime &&
                moment(params.data.lastBulkMessageReceived?.sentTime).unix() <= dateRange)
          : !params.data.lastBulkMessageReceived?.sentTime;
      },

      isFilterActive() {
        return filterActive;
      }
    };
  });
  useEffect(() => {
    setTimeout(() => {
      filterChangedCallback();
    }, 0);
  }, [dateRange, filterActive]);

  return (
    <Stack>
      <Typography sx={{ mx: "auto", py: 1 }}>לא קיבל הודעת דיוור</Typography>
      <Box>
        <ToggleButtonGroup exclusive onChange={handleDateRangeChange}>
          <ToggleButton value="" aria-label="all">
            <Cancel />
          </ToggleButton>
          <ToggleButton value="week" aria-label="week" selected={dateRangeOption == "week"}>
            בשבוע האחרון
          </ToggleButton>
          <ToggleButton value="month" aria-label="month" selected={dateRangeOption == "month"}>
            בחודש האחרון
          </ToggleButton>
          <ToggleButton value="never" aria-label="never" selected={dateRangeOption == "never"}>
            אף פעם
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Stack>
  );
});

export const PhoneNumberCellRenderer = ({
  paramsPhoneNumber,
  showFlag = true,
  textStyle = null
}) => {
  const number = paramsPhoneNumber as PhoneNumber;
  if (!number) {
    return null;
  }

  if (!number.formatInternational) {
    // assuming we got a string number
    return (
      <Typography variant="subtitle2" fontSize={12} sx={{ direction: "rtl", ...textStyle }}>
        {number}
      </Typography>
    );
  }
  return (
    <>
      <Stack direction={"row"} spacing={1}>
        {showFlag && (
          <img
            width={20}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${number.country}.svg`}
          />
        )}
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", direction: "rtl", ...textStyle }}
          fontSize={12}
          noWrap
        >
          {number.formatNational()}
        </Typography>
      </Stack>
    </>
  );
};

export const StringFilterParams = {
  buttons: ["clear"],
  suppressAndOrCondition: true,
  filterOptions: ["contains"]
};

export const DateFilterParams = {
  buttons: ["clear"],
  suppressAndOrCondition: true,
  browserDatePicker: true,
  filterOptions: [
    {
      displayKey: "afterDate",
      displayName: "אחרי תאריך", //need displayName key here otherwise the filter breaks
      test: (filterValue, cellValue) =>
        moment(cellValue).startOf("day").unix() >= moment(filterValue).startOf("day").unix()
    },
    {
      displayKey: "beforeDate",
      displayName: "לפני תאריך", //need displayName key here otherwise the filter breaks
      test: (filterValue, cellValue) =>
        moment(cellValue).startOf("day").unix() <= moment(filterValue).startOf("day").unix()
    }
  ]
};

export const NumberFilterParams = {
  buttons: ["clear"],
  suppressAndOrCondition: true,
  filterOptions: ["greaterThanOrEqual", "lessThanOrEqual"]
};

export const NumberComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  if (typeof valueA === "string") {
    valueA = parseInt(valueA?.replace(/,/g, ""));
  }
  if (typeof valueB === "string") {
    valueB = parseInt(valueB?.replace(/,/g, ""));
  }

  if (isNaN(valueA) || valueA === undefined) {
    valueA = -Infinity;
  }
  if (isNaN(valueB) || valueB === undefined) {
    valueB = -Infinity;
  }

  return Number(valueA) - Number(valueB);
};

const MenuRenderer = (props) => {
  const menuRows = props.menuRows;
  return <MoreMenu menuRows={menuRows} actionContext={props.node} />;
};

const LoadingOverlay = (props) => {
  return <LoadingScreen sx={{ maxHeight: 300 }} />;
};

const VirtualNumberOwnerRenderer = (props) => {
  const { owner } = props?.data?._metadata ?? {};
  const { email, ownerFullName, photoURL, number } = owner ?? {};

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <Avatar sx={{ width: "28px", height: "28px", mr: 1 }} alt={ownerFullName} src={photoURL} />
      </Box>
      <Box>
        <Typography fontSize="0.75rem" variant="body1" fontWeight="bold" color="grey.700">
          {ownerFullName ?? email}
        </Typography>
        <Typography fontSize="0.7rem" variant="body1" color="grey.600">
          {number}
        </Typography>
      </Box>
    </Box>
  );
};

const customCellRenderers = {
  BooleanRenderer,
  ColoredLabelRenderer,
  MultiLineRenderer,
  StatusCellRenderer,
  CallStatusRenderer,
  BudgetFilter
};

const customFrameworkComponents = {
  InterestedInLocationsRenderer,
  MultiLineRenderer,
  BooleanRenderer,
  ColoredLabelRenderer,
  DateTimeRenderer,
  StatusCellRenderer,
  AudioPlayerCellRenderer,
  AutoCompleteEditor,
  NumberFormatEditor,
  CallStatusRenderer,
  BudgetFilter,
  PhoneNumberCellRenderer,
  MenuRenderer,
  LoadingOverlay,
  lastBulkMessageReceivedFilter,
  VirtualNumberOwnerRenderer
};

export {
  BooleanRenderer,
  customCellRenderers,
  AutoCompleteEditor,
  currencyFormatter,
  phoneNumberNationalFormatter,
  customFrameworkComponents
};
