import { Stack, IconButton, Typography } from "@material-ui/core";
import { addDays, isSameDay, startOfDay } from "date-fns";
import { Bedtime, Filter7, WbSunny, WbTwilight } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import type { CalendarEvent } from "../../../@types/sharedSchema";

type CalendarFormTimeStatusProps = {
  event: CalendarEvent;
  onChangeTaskStartTimeStatus: (timeStatus: TimeStatus) => () => void;
};

export function CalendarFormTimeStatus({
  event,
  onChangeTaskStartTimeStatus
}: CalendarFormTimeStatusProps): JSX.Element {
  const { t: translate } = useTranslation();

  const isCustomTimeStatus = getTimeStatusByDate(event.start) === null;

  return (
    <Stack
      sx={isCustomTimeStatus ? { opacity: 0.5 } : undefined}
      gap={2.5}
      direction="row"
      justifyContent="center"
      flexWrap="wrap"
    >
      {taskTimeStatus.map(({ id, icon }) => (
        <Stack
          sx={id === getTimeStatusByDate(event.start) ? { color: "primary.main" } : undefined}
          alignItems="center"
          gap={1}
          key={id}
        >
          <IconButton color="inherit" onClick={onChangeTaskStartTimeStatus(id)}>
            {icon}
          </IconButton>
          <Typography variant="overline">
            {translate(`calendarPage.form.timeStatusLabel.${id}`)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

const timeStatus = ["today", "tomorrow", "nextWeek", "nextMonth"] as const;

export type TimeStatus = typeof timeStatus[number];

type TaskTimeStatus = {
  id: TimeStatus;
  icon: JSX.Element;
};

const taskTimeStatus: TaskTimeStatus[] = [
  {
    id: "today",
    icon: <WbSunny />
  },
  {
    id: "tomorrow",
    icon: <WbTwilight />
  },
  {
    id: "nextWeek",
    icon: <Filter7 />
  },
  {
    id: "nextMonth",
    icon: <Bedtime />
  }
];

export function getTimeStatusByDate(date: Date): TimeStatus {
  const today = startOfDay(new Date());

  if (isSameDay(date, today)) return "today";

  if (isSameDay(date, addDays(today, 1))) return "tomorrow";

  if (isSameDay(date, addDays(today, 7))) return "nextWeek";

  if (isSameDay(date, addDays(today, 30))) return "nextMonth";

  return null;
}

export function getTimeStatusDateByTimeStatus(timeStatus: TimeStatus): Date {
  const today = startOfDay(new Date());

  switch (timeStatus) {
    case "today":
      return today;
    case "tomorrow":
      return addDays(today, 1);
    case "nextWeek":
      return addDays(today, 7);
    case "nextMonth":
      return addDays(today, 30);
  }
}
