import * as Yup from "yup";
import { useState } from "react";
import { merge } from "lodash";
import { isBefore } from "date-fns";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack5";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import { useFormik, Form, FormikProvider } from "formik";
import "rc-time-picker/assets/index.css";
import { roundToNearestMinutes, addHours } from "date-fns";
import {
  Box,
  Tab,
  Tabs,
  Stack,
  Button,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { LoadingButton, MobileDatePicker, MobileTimePicker } from "@material-ui/lab";
import ColorSinglePicker from "../../ColorSinglePicker";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { calendarEventStatuses, calendarEventTypes } from "../../../@types/sharedSchema";
import { NewMultipleContactPicker } from "../listingsMatch/NewContactPicker";
import {
  parseAttendeesToContacts,
  parseContactsToAttendees,
  getTaskTimeForStartOfToday
} from "utils/calendar/helper";
import { CheckedMenuItemLabel } from "components/__haim/Common/CheckedMenuItemLabel";
import { CalendarFormTimeStatus, getTimeStatusDateByTimeStatus } from "./CalendarFormTimeStatus";
import type { KeyboardEvent } from "react";
import type { TimeStatus } from "./CalendarFormTimeStatus";
import type { CalendarEvent, CalendarEventType } from "../../../@types/sharedSchema";

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  "#00AB55", // theme.palette.primary.main,
  "#1890FF", // theme.palette.info.main,
  "#94D82D", // theme.palette.success.main,
  "#FFC107", // theme.palette.warning.main,
  "#FF4842", // theme.palette.error.main
  "#04297A", // theme.palette.info.darker
  "#7A0C2E" // theme.palette.error.darker
];

const getInitialValues = (event: CalendarEvent | null): CalendarEvent => {
  const _event = {
    title: "",
    description: "",
    textColor: "#1890FF",
    allDay: false,
    start: (event?.start ?? roundToNearestMinutes(new Date(), { nearestTo: 15 })) as Date,
    end: (event?.end ?? addHours(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 1)) as Date,
    attendees: [],
    eventType: event?.eventType ?? "meeting"
  };

  if (event) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

type CalendarFormProps = {
  event: CalendarEvent;
  onCancel: VoidFunction;
  onUpdate: (CalendarEvent) => Promise<boolean>;
  onDelete: (eventId: string) => Promise<void>;
};

export default function CalendarForm({ event, onUpdate, onDelete, onCancel }: CalendarFormProps) {
  const [shouldMakeEventAllDay, setShouldMakeEventAllDay] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const isCreating = !event?.id;

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required("Title is required"),
    description: Yup.string().max(5000)
  });

  const formik = useFormik({
    initialValues: getInitialValues(event),
    validationSchema: EventSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const parsedContactsToAttendees = parseContactsToAttendees(values.attendees);

      const newEvent: CalendarEvent = {
        id: event.id,
        title: values.title,
        description: values.description,
        backgroundColor: values.textColor,
        textColor: values.textColor,
        allDay: values.allDay,
        start: values.start,
        end: values.end,
        attendees: parsedContactsToAttendees,
        eventType: values.eventType as CalendarEventType
      };

      if (isTaskEvent) {
        newEvent.status = values.status ?? "pending";

        const makeEventAllDay = shouldMakeEventAllDay || isCreating;

        if (makeEventAllDay) {
          const { start, end } = getTaskTimeForStartOfToday(values.start);

          newEvent.allDay = true;

          newEvent.start = start;
          newEvent.end = end;
        }
      } else {
        newEvent.status = null;
      }

      onUpdate(newEvent)
        .then((success) => {
          if (success) {
            const message =
              event.eventType === "task"
                ? t("calendarPage.form.taskUpdatedSuccesfully")
                : t("calendarPage.form.eventUpdatedSuccesfully");
            enqueueSnackbar(message, {
              variant: "success"
            });
          } else {
            const message =
              event.eventType === "task"
                ? t("calendarPage.form.taskOperationFailure")
                : t("calendarPage.form.eventOperationFailure");
            enqueueSnackbar(message, {
              variant: "error"
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });

      onCancel();
      resetForm();
      setSubmitting(false);
      setShouldMakeEventAllDay(false);
    }
  });

  const { values, errors, touched, isSubmitting, getFieldProps, handleSubmit, setFieldValue } =
    formik;

  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      onDelete(event.id);
      enqueueSnackbar("Delete event success", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Delete event failed", { variant: "error" });
    }
  };

  const handleChangeTaskTimeStatus = (timeStatus: TimeStatus) => (): void => {
    const newStartDate = getTimeStatusDateByTimeStatus(timeStatus);

    const { start, end } = getTaskTimeForStartOfToday(newStartDate);

    setFieldValue("end", end);
    setFieldValue("start", start);
    setShouldMakeEventAllDay(true);
  };

  const handleTitleSubmitOnEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key !== "Enter") return;

    if (!e.repeat) {
      const formTarget = e.target as EventTarget & { form: HTMLFormElement };
      formTarget.form.requestSubmit();
    }

    e.preventDefault();
  };

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  const parsedAttendeesToContacts = parseAttendeesToContacts(values.attendees);

  const isTaskEvent = values.eventType === "task";

  const dialogTitle = isCreating
    ? isTaskEvent
      ? t("calendarPage.control.newTask")
      : t("calendarPage.control.newMeeting")
    : isTaskEvent
    ? t("calendarPage.control.editTask")
    : t("calendarPage.control.editMeeting");

  return (
    <>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <DialogContent sx={{ py: 0, overflowY: "unset" }}>
            <Tabs
              centered
              sx={{ mb: 2 }}
              value={values.eventType}
              onChange={(_, newValue) => setFieldValue("eventType", newValue)}
            >
              {calendarEventTypes.map((item) => (
                <Tab
                  label={t(`calendarPage.form.eventTypeLabel.${item}`)}
                  value={item}
                  key={item}
                />
              ))}
            </Tabs>
            <TextField
              fullWidth
              multiline
              sx={{ mb: 3 }}
              label={t("calendarPage.form.title")}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              onKeyDown={handleTitleSubmitOnEnter}
              {...getFieldProps("title")}
            />
            {isTaskEvent ? (
              <Stack sx={{ mb: 2 }} gap={4}>
                <CalendarFormTimeStatus
                  event={values}
                  onChangeTaskStartTimeStatus={handleChangeTaskTimeStatus}
                />
                <Stack gap={2}>
                  <MobileDatePicker
                    label={t("calendarPage.form.date")}
                    value={values.start}
                    minDate={new Date()}
                    disabled={isSubmitting}
                    onChange={(value) => {
                      const { start, end } = getTaskTimeForStartOfToday(value);
                      setFieldValue("start", start);
                      setFieldValue("end", end);
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: 112 }} variant="standard" />
                    )}
                  />
                </Stack>
                <FormControl fullWidth>
                  <InputLabel>{t("calendarPage.form.status")}</InputLabel>
                  <Select
                    required
                    name="status"
                    value={values.status ?? "pending"}
                    label={t("calendarPage.form.status")}
                    onChange={({ target: { value } }) => setFieldValue("status", value)}
                    renderValue={(selected) => t(`calendarPage.form.statusLabel.${selected}`)}
                  >
                    {calendarEventStatuses.map((item) => (
                      <MenuItem value={item} key={item}>
                        <CheckedMenuItemLabel selected={values.status === item}>
                          {t(`calendarPage.form.statusLabel.${item}`)}
                        </CheckedMenuItemLabel>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            ) : (
              <>
                <Stack sx={{ mb: 2 }} direction="row" gap={2}>
                  <MobileDatePicker
                    label={t("calendarPage.form.startDay")}
                    minDate={new Date()}
                    inputFormat="dd/MM/yyyy"
                    value={values.start}
                    onChange={(date) => {
                      setFieldValue("start", date);
                      if (!touched.end && values.end) {
                        const newEnd = moment(date)
                          .add(values.end.getTime() - values.start.getTime(), "ms")
                          .toDate();
                        setFieldValue("end", newEnd);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: 112 }} variant="standard" />
                    )}
                  />
                  <MobileTimePicker
                    label={t("calendarPage.form.startTime")}
                    inputFormat="HH:mm"
                    minutesStep={5}
                    value={values.start}
                    onChange={(date) => {
                      setFieldValue("start", date);
                      if (!touched.end && values.end) {
                        const newEnd = moment(date)
                          .add(values.end.getTime() - values.start.getTime(), "ms")
                          .toDate();
                        setFieldValue("end", newEnd);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: 80 }} variant="standard" />
                    )}
                    ampm={false}
                    ampmInClock={false}
                  />
                </Stack>
                <Stack sx={{ mb: 3 }} direction="row" gap={2}>
                  <MobileDatePicker
                    label={t("calendarPage.form.endDay")}
                    minDate={new Date()}
                    inputFormat="dd/MM/yyyy"
                    value={values.end}
                    onChange={(date) => {
                      setFieldValue("end", date);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: 112 }} variant="standard" />
                    )}
                  />
                  <MobileTimePicker
                    label={t("calendarPage.form.endTime")}
                    inputFormat="HH:mm"
                    minutesStep={5}
                    value={values.end}
                    onChange={(date) => {
                      setFieldValue("end", date);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: 80 }} variant="standard" />
                    )}
                    ampm={false}
                    ampmInClock={false}
                  />
                </Stack>
              </>
            )}
            <Stack gap={2}>
              <NewMultipleContactPicker
                selectedContacts={parsedAttendeesToContacts}
                setSelectedContacts={(newContacts) => setFieldValue("attendees", newContacts)}
              />
              {!isTaskEvent && (
                <TextField
                  fullWidth
                  multiline
                  maxRows={4}
                  label={t("calendarPage.form.description")}
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              )}
              <ColorSinglePicker {...getFieldProps("textColor")} colors={COLOR_OPTIONS} />
            </Stack>
          </DialogContent>
          <DialogActions>
            {!isCreating && (
              <Tooltip title="Delete Event">
                <IconButton onClick={handleDelete}>
                  <Icon icon={trash2Fill} width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button type="button" variant="outlined" color="inherit" onClick={onCancel}>
              {t("general.cancel")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={isDateError || Object.keys(errors).length > 0}
              loading={isSubmitting}
            >
              {isCreating ? t("calendarPage.control.add") : t("calendarPage.control.edit")}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </>
  );
}
