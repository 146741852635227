import useAuth from "hooks/useAuth";
import useFirestore from "hooks/useFirestore";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export const posthogKey = "phc_zXwlJqtzduWvqtnYJbYXIpShhItwxUB4p6gmHC2FtSJ";
export const posthogHost = "https://us.i.posthog.com";
export const posthogOptions = {
  api_host: posthogHost
};

export default function Posthog() {
  const posthog = usePostHog();
  const { user } = useAuth();
  const { userSettings } = useFirestore();

  useEffect(() => {
    if (!posthog || !user) {
      return;
    }

    posthog.identify(user.email, {
      email: user.email
    });
  }, [user]);

  useEffect(() => {
    if (!posthog || !userSettings || !user) {
      console.log("skipping", posthog, userSettings, user);

      return;
    }

    console.log("userSettings", userSettings);
    posthog.identify(user.email);

    posthog.setPersonProperties({
      email: user.email,
      name: userSettings.ownerFullName,
      phone: userSettings.ownerPhoneNumber,
      companyName: userSettings.companyName,
      advancedFeatures: userSettings.advancedFeatures,
      isPaidCustomer: userSettings.advancedFeatures?.length > 0
    });
  }, [posthog, userSettings, user]);

  return null;
}
