import { parsePhoneNumber } from "utils/Haim/phoneNumbersUtils";
import { set, addDays, startOfDay } from "date-fns";
import type { CalendarEventAttendee, RealEstateContact } from "../../@types/sharedSchema";

type TaskStartDateAndEndDate = {
  start: Date;
  end: Date;
};

export function getTaskTimeForStartOfToday(date?: Date): TaskStartDateAndEndDate {
  const targetDate = date ?? new Date();

  const startOfDayNowDate = startOfDay(targetDate);
  const tomorrowStartOfDay = startOfDay(addDays(targetDate, 1));

  return {
    start: startOfDayNowDate,
    end: tomorrowStartOfDay
  };
}

export function adjustTaskTimeForToday(startDate: Date, endDate: Date): TaskStartDateAndEndDate {
  const today = new Date();

  const start = set(today, {
    hours: startDate.getHours(),
    minutes: startDate.getMinutes(),
    seconds: startDate.getSeconds(),
    milliseconds: startDate.getMilliseconds()
  });

  const end = set(today, {
    hours: endDate.getHours(),
    minutes: endDate.getMinutes(),
    seconds: endDate.getSeconds(),
    milliseconds: endDate.getMilliseconds()
  });

  return { start, end };
}

export function parseContactsToAttendees(
  attendees: CalendarEventAttendee[] | RealEstateContact[]
): CalendarEventAttendee[] {
  type MergedAttendeeAndContact = RealEstateContact & CalendarEventAttendee;

  const parsedContactsToAttendees = attendees.map(
    ({
      id,
      lastName,
      firstName,
      callerLastName,
      callerFirstName,
      callerPhoneNumber,
      phoneNumberInternational
    }: MergedAttendeeAndContact) => {
      let parsedAttendee: CalendarEventAttendee = {
        lastName,
        firstName,
        phoneNumberInternational
      };

      const validPhoneNumber = parsePhoneNumber(callerPhoneNumber)?.format("E.164") ?? id;

      if (validPhoneNumber) {
        parsedAttendee.phoneNumberInternational = validPhoneNumber;
      }

      if (callerFirstName) {
        parsedAttendee.firstName = callerFirstName;
      }

      if (callerLastName) {
        parsedAttendee.lastName = callerLastName;
      }

      return parsedAttendee;
    }
  );

  return parsedContactsToAttendees;
}

export function parseAttendeesToContacts(attendees: CalendarEventAttendee[]): RealEstateContact[] {
  const parsedAttendeesToContacts = attendees.map(
    ({ firstName, lastName, phoneNumberInternational, ...rest }) => {
      let parsedContact = rest as RealEstateContact;

      if (phoneNumberInternational) {
        parsedContact.id = phoneNumberInternational;
        parsedContact.callerPhoneNumber = phoneNumberInternational;
      }

      if (firstName) {
        parsedContact.callerFirstName = firstName;
      }

      if (lastName) {
        parsedContact.callerLastName = lastName;
      }

      return parsedContact;
    }
  );

  return parsedAttendeesToContacts;
}
