// routes
import ModalProvider from "mui-modal-provider";
import { FirestoreProvider } from "contexts/FirestoreContext";
import { WebotServerProvider } from "contexts/WebotServerContext";
import { AppProvider } from "contexts/AppContext";
import Router from "./routes";
// import { BrowserRouter } from "react-router-dom";
// theme
import ThemeConfig from "./theme";
// hooks
import useAuth from "./hooks/useAuth";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen from "./components/LoadingScreen";
import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeLocalization from "./components/ThemeLocalization";

import "customStyles.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

import { CrmSpecificationsProvider } from "contexts/CrmSpecificationsContext";
import CrispSettings from "components/__haim/Common/CrispSettings";
import { CalendarProvider } from "contexts/CalendarContext";
import { MetaPixel } from "components/analytics/MetaPixel";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { PostHogProvider } from "posthog-js/react";
import Posthog, { posthogKey, posthogOptions } from "components/analytics/Posthog";

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ModalProvider>
                <PostHogProvider apiKey={posthogKey} options={posthogOptions}>
                  <FirestoreProvider>
                    <WebotServerProvider>
                      <AppProvider>
                        <QueryParamProvider
                          adapter={ReactRouter6Adapter}
                          options={{
                            includeAllParams: false,
                            removeDefaultsFromUrl: true,
                            skipUpdateWhenNoChange: true,
                            enableBatching: true
                          }}
                        >
                          <CalendarProvider>
                            <CrmSpecificationsProvider>
                              <Settings />
                              <ScrollToTop />
                              <CrispSettings />
                              <Posthog />
                              <MetaPixel />
                              <GoogleAnalytics />
                              {isInitialized ? <Router /> : <LoadingScreen />}
                            </CrmSpecificationsProvider>
                          </CalendarProvider>
                        </QueryParamProvider>
                      </AppProvider>
                    </WebotServerProvider>
                  </FirestoreProvider>
                </PostHogProvider>
              </ModalProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
