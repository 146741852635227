export type Order = "asc" | "desc";

export function getComparator(
  order: Order,
  orderBy: string
): (a: Record<string, any>, b: Record<string, any>) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function textIncludes(target: string | null, filter: string): boolean {
  if (!target) return false;
  const [newTarget, newFilter] = [target, filter].map((text) =>
    text.replace(/[^\p{L}\p{N}]/gu, "").toLowerCase()
  );
  return newTarget.includes(newFilter);
}

export function getRandomColor(input: string, saturation = 95, lightness = 75): string {
  const stringUniqueHash = [...input].reduce(
    (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc),
    0
  );
  return `hsl(${stringUniqueHash % 360}, ${saturation}%, ${lightness}%)`;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): -1 | 1 | 0 {
  const aValue = a[orderBy] ?? "";
  const bValue = b[orderBy] ?? "";

  if (bValue < aValue) return -1;
  if (bValue > aValue) return 1;

  return 0;
}
