export const status = ["sold", "onSale", "removed", "frozen", "inNegotiation"] as const;

export type Status = typeof status[number];

export const visibleListingStatus: Status[] = ["onSale", "inNegotiation"];

export const invisibleListingStatus: Status[] = ["sold", "removed", "frozen"];

export const listingType = [
  "lot",
  "halls",
  "hotel",
  "duplex",
  "studio",
  "offices",
  "clinics",
  "triplex",
  "basement",
  "pentHouse",
  "apartment",
  "warehouses",
  "parkingLot",
  "privateHouse",
  "officeBuilding",
  "sharedWorkspace",
  "gardenApartment",
  "industrialBuildings",
  "residentialBuilding",
  "storesCommercialSpace"
] as const;

export type ListingType = typeof listingType[number];

export const listingState = [
  "likeNew",
  "renovated",
  "maintained",
  "newConstruction",
  "needsRenovation"
] as const;

export type ListingState = typeof listingState[number];

export const visibility = ["private", "organization"] as const;

export type Visibility = typeof visibility[number];

export const parkingSpotsCount = [1, 2, 3, 4, 5];

export type ListingParkingSpot = typeof parkingSpotsCount[number];
