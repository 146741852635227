import { listingDataCollectionGroup, realEstateListingsCollection } from "./collections";
import { query, orderBy, where, and, or, limit, getDocs } from "firebase/firestore";
import { visibleListingStatus } from "../../@types/listings/unions";
import { getDateStartOfTheDay } from "./helper";
import type { Listing } from "../../@types/listings/listing";
import type { User } from "../../@types/listings/user";
import type { ListingTabType } from "components/_dashboard/listings/table/filters/ListingTableFilters";
import type { Query, QueryConstraint } from "firebase/firestore";

type FirestoreListingQueryOptions = {
  userEmail: string;
  organizationId: string | null;
  listingTabType: ListingTabType;
};

export function getNewFirestoreListingQuery({
  userEmail,
  organizationId,
  listingTabType
}: FirestoreListingQueryOptions): Query<Listing> {
  if (listingTabType === "inMiniSite") return getValidListingsInMiniSiteQuery(userEmail);

  let queryConstraints: QueryConstraint[] = [];

  if (listingTabType === "all") {
    queryConstraints = [where("visibility", "==", "public")];
  }

  if (listingTabType === "myOrganization") {
    queryConstraints = [
      where("createdByOrganization", "==", organizationId ?? ""),
      where("visibility", "in", ["organization", "public"])
    ];
  }

  if (listingTabType === "myListings") {
    queryConstraints = [where("owners", "array-contains", userEmail)];
  }

  if (listingTabType === "saved") {
    queryConstraints = [
      where("userFavorites", "array-contains", userEmail),
      where("visibility", "in", ["organization", "public"])
    ];
  }

  queryConstraints = [...queryConstraints, orderBy("createdTime", "desc")];

  return query(realEstateListingsCollection, ...queryConstraints);
}

export function getValidListingsInMiniSiteQuery(
  userEmail: string,
  documentLimit?: number
): Query<Listing> {
  return query(
    realEstateListingsCollection,
    or(
      where("owners", "array-contains", userEmail),
      where("userFavorites", "array-contains", userEmail)
    ),
    ...(documentLimit ? [limit(documentLimit)] : [])
  );
}

export function getListingsSuggestionInMiniSiteQuery(
  userEmail: string,
  documentLimit?: number
): Query<Listing> {
  return query(
    realEstateListingsCollection,
    and(
      where("status", "in", visibleListingStatus),
      where("exclusivity", ">=", getDateStartOfTheDay()),
      or(
        where("owners", "array-contains", userEmail),
        where("userFavorites", "array-contains", userEmail)
      )
    ),
    ...(documentLimit ? [limit(documentLimit)] : [])
  );
}

export async function getListingDataByListingAlias(listingAlias: string): Promise<User | null> {
  const customerDataSnapshot = await getDocs(
    query(listingDataCollectionGroup, where("listingAlias", "==", listingAlias), limit(1))
  );

  if (customerDataSnapshot.empty) return null;

  const newCustomerData = customerDataSnapshot.docs[0].data();

  return newCustomerData;
}

export async function checkListingAliasAvailability(listingAlias: string): Promise<boolean> {
  const customerData = await getListingDataByListingAlias(listingAlias);
  return customerData === null;
}
