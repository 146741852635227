import { db } from "firebaseConfig";
import { collection, collectionGroup } from "firebase/firestore";
import type { User } from "../../@types/listings/user";
import type { Listing } from "../../@types/listings/listing";
import type { FirestoreDataConverter } from "firebase/firestore";

export const listingConverter: FirestoreDataConverter<Listing> = {
  toFirestore(listing: Listing) {
    return listing;
  },
  fromFirestore(snapshot, options) {
    const { id } = snapshot;
    const data = snapshot.data(options);

    return { ...data, id } as Listing;
  }
};

export const realEstateListingsCollection = collection(db, "real-estate-listings").withConverter(
  listingConverter
);

export const listingUserConverter: FirestoreDataConverter<User> = {
  toFirestore(user: User) {
    return user;
  },
  fromFirestore(snapshot, options) {
    const { id } = snapshot;
    const data = snapshot.data(options);

    return { ...data, id } as User;
  }
};

export const listingDataCollectionGroup = collectionGroup(db, "listingData").withConverter(
  listingUserConverter
);
