import { firebaseConfig } from "./config";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

export const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
db.settings({ experimentalAutoDetectLongPolling: true, ignoreUndefinedProperties: true });

export const storage = firebaseApp.storage();

export const login = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const loginWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  if (window?.location?.hostname?.includes("app.webot.co.il")) {
    return firebase.auth().signInWithRedirect(provider);
  } else {
    return firebase.auth().signInWithPopup(provider);
  }
};

export const loginWithFaceBook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const loginWithTwitter = () => {
  const provider = new firebase.auth.TwitterAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const register = (email: string, password: string, firstName: string, lastName: string) =>
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      firebase
        .firestore()
        .collection("users")
        .doc(res.user?.uid)
        .set({
          uid: res.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`
        });
    });

export const logout = async () => {
  await firebase.auth().signOut();
  window.location.reload();
};

export const resetPassword = async (email: string) => {
  await firebase.auth().sendPasswordResetEmail(email);
};

export { db, firebase };
