import { AgGridColumnProps } from "ag-grid-react";
import UploadExplanationRealEstate from "../../components/__haim/ImportLeads/UploadExplanationRealEstate";
import { validateRealEstateColumnValues } from "./../ImportLeads/realEstateImportLeadsValidations";
import { ICrmSpecifications } from "./../../@types/crmSpecifications";
import {
  NumberComparator,
  currencyFormatter,
  StringFilterParams,
  NumberFilterParams
} from "./../../utils/Haim/agGridCellRenderers";
import {
  callerFirstNameCol,
  callerLastNameCol,
  contactOriginCol,
  createdTimeCol,
  updatedTimeCol,
  lastBulkMessageReceivedTimeCol,
  optedOutOfBulkMessagesCol,
  isInSpamDatabaseCol,
  additionalNotesCol
} from "./GenericCrmSpecifications";
import { metadataParsedCallerPhoneNumberCol } from "./GenericCrmSpecifications";
import { mappedCustomerSeriousness, mappedCustomerTypes } from "utils/contacts/mappedTs";
import { getListingAddressString } from "utils/Haim/utils";
import { textIncludes } from "utils/table";

export class RealEstateCrmSpecifications implements ICrmSpecifications {
  getDefaultDataColumns(): AgGridColumnProps[] {
    return realEstateColumns;
  }

  getLeadsImportUISection() {
    return <UploadExplanationRealEstate />;
  }

  runLeadsImportValidations(parsedRow: any, rawRow: any) {
    return validateRealEstateColumnValues(parsedRow, rawRow);
  }

  getLeadsImportDemoFileName() {
    return "/static/_haim/demoImportFileRealEstate.xlsx";
  }
}

export const customerTypePossibleValues = Object.keys(mappedCustomerTypes);
export const customerSeriousnessPossibleValues = Object.keys(mappedCustomerSeriousness);
export const optedOutOfBulkMessagesPossibleValues = ["", false, true];

export const customerBudgetCol: AgGridColumnProps = {
  field: "customerBudget",
  minWidth: 150,
  headerName: "תקציב",
  valueFormatter: currencyFormatter,
  filter: "BudgetFilter",
  comparator: NumberComparator,
  editable: true
};

export const interestedInLocationsCol: AgGridColumnProps = {
  field: "interestedInLocations",
  filter: true,
  minWidth: 300,
  headerName: "מעוניין באזורים",
  cellRenderer: "InterestedInLocationsRenderer",
  valueGetter: ({ data: { interestedInLocations } }) => {
    return JSON.stringify(interestedInLocations);
  },
  filterParams: {
    buttons: ["clear"],
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
    textCustomComparator: (_filterOption: string, columnValue: string, filterText: string) => {
      const splittedFilterValue = filterText.trim().split(" ").filter(Boolean);

      if (!splittedFilterValue.length) return true;

      const interestedInLocations = JSON.parse(columnValue);

      if (!interestedInLocations?.length) return false;

      let isMatch = false;

      for (const filterValue of splittedFilterValue) {
        const parsedColumnValue = interestedInLocations.map(getListingAddressString).join(", ");

        isMatch = textIncludes(parsedColumnValue, filterValue);

        if (isMatch) break;
      }

      return isMatch;
    }
  }
};

export const neighborhoodCol: AgGridColumnProps = {
  field: "neighborhood",
  headerName: "שכונה",
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const streetCol: AgGridColumnProps = {
  field: "street",
  headerName: "רחוב",
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const roomsMinCol: AgGridColumnProps = {
  field: "roomsMin",
  headerName: "חדרים - מ",
  cellEditor: "agSelectCellEditor",
  cellEditorParams: {
    values: [""].concat(
      [...Array(21).keys()].map((e, i) => (e / 2).toString() + (i === 20 ? "+" : ""))
    ) // 0 - 10+ rooms
  },
  filter: "agNumberColumnFilter",
  filterParams: NumberFilterParams,
  editable: true
};

export const roomsMaxCol: AgGridColumnProps = {
  field: "roomsMax",
  headerName: "חדרים - עד",
  cellEditor: "agSelectCellEditor",
  cellEditorParams: {
    values: [""].concat(
      [...Array(21).keys()].map((e, i) => (e / 2).toString() + (i === 20 ? "+" : "")).slice(1)
    ) // 0.5 - 10+ rooms
  },
  filter: "agNumberColumnFilter",
  filterParams: NumberFilterParams,
  editable: true
};

export const customerTypeCol: AgGridColumnProps = {
  field: "customerType",
  headerName: "סוג לקוח",
  cellEditor: "agSelectCellEditor",
  cellEditorParams: {
    values: customerTypePossibleValues
  },
  cellRenderer: "ColoredLabelRenderer",
  cellRendererParams: {
    colors: {
      מוכר: "success",
      קונה: "warning",
      שוכר: "info",
      משכיר: "error"
    }
  },
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const customerSeriousnessCol: AgGridColumnProps = {
  field: "customerSeriousness",
  headerName: "רצינות",
  cellEditor: "agSelectCellEditor",
  minWidth: 120,
  cellEditorParams: {
    values: customerSeriousnessPossibleValues
  },
  cellRenderer: "ColoredLabelRenderer",
  cellRendererParams: {
    colors: {
      "לקוח חם": "error",
      "לא רציני": "warning",
      רציני: "success",
      "אין התאמה": "warning"
    }
  },
  filter: true,
  filterParams: StringFilterParams,
  editable: true
};

export const realEstateColumns: AgGridColumnProps[] = [
  metadataParsedCallerPhoneNumberCol,
  callerFirstNameCol,
  callerLastNameCol,
  customerTypeCol,
  customerBudgetCol,
  interestedInLocationsCol,
  neighborhoodCol,
  streetCol,
  roomsMinCol,
  roomsMaxCol,
  customerSeriousnessCol,
  additionalNotesCol,
  createdTimeCol,
  updatedTimeCol,
  contactOriginCol,
  lastBulkMessageReceivedTimeCol,
  optedOutOfBulkMessagesCol,
  isInSpamDatabaseCol
];
