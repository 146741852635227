import { createContext } from "react";
import { orgAdminStatsResponseSchema, WebotServerContextProps } from "../@types/webotServer";
import useAuth from "../hooks/useAuth";
import useFirestore from "hooks/useFirestore";

import axios from "axios";
import { WEBOT_SERVER_ENDPOINT } from "logic/Consts";
import { CalendarEvent, REST_schema } from "../@types/sharedSchema";
import type { MarkNoSpamContactsResponse } from "../@types/webotServer";
import type { ReactNode } from "react";

const WebotServerContext = createContext({} as WebotServerContextProps);

type WebotServerProviderProps = {
  children: ReactNode;
};

function WebotServerProvider({ children }: WebotServerProviderProps) {
  const { user } = useAuth();

  const baseUrl = `${WEBOT_SERVER_ENDPOINT}/api/v1`;

  const getOrgAdminStats = async (dateFrom: Date, organizationId?: string) => {
    const url = `${baseUrl}/get-data/org-admin-stats`;
    const results = await sendWebotRequest(url, "POST", {
      dateFrom: dateFrom,
      organizationId: organizationId
    });

    const stats = (await results.json()) as orgAdminStatsResponseSchema;

    return stats;
  };

  const markNoSpamContacts = async (): Promise<MarkNoSpamContactsResponse> => {
    const url = `${baseUrl}/contacts/mark-no-spam`;
    const results = await sendWebotRequest(url, "POST");
    const data = await results.json();

    return data;
  };

  const getBuyersSuggestionsForListingAndRealtor = async (
    listingId: string
  ): Promise<REST_schema.RelevantContactsForListingResponse> => {
    const url = `${baseUrl}/getBuyersSuggestionsForListingAndRealtor/${listingId}`;
    const results = await sendWebotRequest(url, "POST");

    const data = (await results.json()) as REST_schema.RelevantContactsForListingResponse;

    return data;
  };

  const webotCalendarOnEventCreated = async (event: CalendarEvent): Promise<void> => {
    const url = `${baseUrl}/webot-calendar/create-event`;
    await sendWebotRequest(url, "POST", { event });
  };

  const webotCalendarOnEventUpdated = async (event: CalendarEvent): Promise<void> => {
    const url = `${baseUrl}/webot-calendar/update-event`;
    await sendWebotRequest(url, "POST", { event });
  };

  const webotCalendarOnEventDeleted = async (event: CalendarEvent): Promise<void> => {
    const url = `${baseUrl}/webot-calendar/delete-event`;
    await sendWebotRequest(url, "POST", { event });
  };
  const sendWebotRequest = async (url: string, method: "POST", body: any = {}) => {
    const userIdToken = await user?.getIdToken();

    try {
      return await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
          Authorization: userIdToken as string,
          "Content-Type": "application/json"
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <WebotServerContext.Provider
      value={{
        getOrgAdminStats,
        markNoSpamContacts,
        getBuyersSuggestionsForListingAndRealtor,
        webotCalendarOnEventCreated,
        webotCalendarOnEventUpdated,
        webotCalendarOnEventDeleted
      }}
    >
      {children}
    </WebotServerContext.Provider>
  );
}

export { WebotServerProvider, WebotServerContext };
