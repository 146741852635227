// import { WorkspaceCreationStatus } from './../../../@types/firestore';
import Onboarding from "components/__haim/NewUserForm/onboarding";
import { UserSettings } from "./../@types/firestore";
import useFirestore from "hooks/useFirestore";
// import NewWorkspaceCreationPage from 'pages/dashboard/NewWorkspaceCreationPage';
import { useState, ReactNode, useMemo, memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
// pages
// import Login from '../../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

const shouldShowOnboarding = (userSettings: UserSettings) => {
  const createdTime = userSettings?.createdTime;
  const newOnboardingStartDate = new Date("2024-05-01");

  // For old users, require less information (only phone number)
  if (
    !createdTime ||
    (typeof createdTime === "string" && new Date(createdTime) < newOnboardingStartDate) ||
    (createdTime instanceof Date && createdTime < newOnboardingStartDate)
  ) {
    return !userSettings?.ownerPhoneNumber;
  }

  return !userSettings?.onboardingCompleted;
};

export default function NewUserGuard({ children }: AuthGuardProps) {
  const { userSettings } = useFirestore();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!!userSettings && shouldShowOnboarding(userSettings)) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    console.debug("redirecting to new user page");
    return <Onboarding />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
