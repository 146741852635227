import { Stack } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import type { ReactNode } from "react";

type CheckedMenuLabelProps = {
  selected: boolean;
  children: ReactNode;
};

export function CheckedMenuItemLabel({ selected, children }: CheckedMenuLabelProps): JSX.Element {
  return (
    <Stack direction="row" gap={1}>
      {selected && <Check color="primary" />}
      {children}
    </Stack>
  );
}
