import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { Suspense, lazy } from "react";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
import NewUserGuard from "guards/NewUserGuard";
import useFirestore from "hooks/useFirestore";
import { Box } from "@material-ui/system";
import { LinearProgress } from "@material-ui/core";

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed"
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

function renderDashboardMainPage(isLoadingUser: boolean, isAdmin: boolean): React.ReactElement {
  if (isLoadingUser) {
    return (
      <Box my={4} p={2} sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  if (isAdmin) {
    return <AdminPage />;
  }

  return <DashboardMain />;
}

export default function Router() {
  const { isLoadingUser, isAdminOfTheOrganization } = useFirestore();

  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> }
      ]
    },
    // Quick route for bot builder
    {
      path: "nadlan",
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard/autoreplyBotSettings/bot-autoreply" replace />
        }
      ]
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <NewUserGuard>
            <DashboardLayout />
          </NewUserGuard>
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Navigate to="/dashboard/main" replace /> },
        { path: "main", element: renderDashboardMainPage(isLoadingUser, isAdminOfTheOrganization) },
        { path: "users", element: <UserListPage /> },
        { path: "contacts", element: <ContactsPage /> },
        { path: "calls", element: <CallsListPage /> },
        { path: "newCalls", element: <CallsPage /> },
        {
          path: "leads",
          children: [{ path: "import", element: <ImportLeadsPage /> }]
        },
        { path: "calendar", element: <CalendarPage /> },
        { path: "listings", element: <ListingsPage /> },
        { path: "listingsMatch", element: <ListingsMatchPage /> },
        { path: "listingMatchToContacts", element: <ListingMatchToContactsPage /> },
        { path: "editContactBeforeListingsMatch", element: <EditContactBeforeListingsMatchPage /> },
        { path: "settings", element: <SettingPage /> },
        { path: "unanswered-calls-bot-responses", element: <InDevelopmentPage /> },
        {
          path: "whatsAppWeb",
          children: [
            { path: "status", element: <WhatsAppExtensionTutorialPage /> },
            { path: "sendMessages", element: <WhatsAppWebSendMessagesPage /> }
          ]
        },
        {
          path: "autoreplyBotSettings",
          children: [{ path: "bot-autoreply", element: <BotAutoReplyPage /> }]
        }
      ]
    },
    // Public Listings Routes
    {
      path: "listings",
      children: [
        { path: "", element: <Navigate to="/dashboard/main" replace /> },
        { path: "u/:listingAlias", element: <CustomerListings /> },
        { path: ":listingAlias/:listingId", element: <PublicListingPage /> }
      ]
    },
    {
      path: "myListings",
      children: [
        { path: ":listingFavoritesId", element: <ListingsMatchClientPublicPage /> },
        { path: ":listingFavoritesId/:listingId", element: <ListingsMatchClientPublicPage /> }
      ]
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        // Replacing the default 404 redirect with a custom one
        // { path: '*', element: <Navigate to="/404" replace /> }
        { path: "*", element: <BotShortLinksRedirectPage /> }
      ]
    },
    {
      path: "",
      element: <Navigate to="/dashboard" replace />
    },
    // Replacing the default 404 redirect with a custom one
    // { path: '*', element: <Navigate to="/404" replace /> }
    { path: "*", element: <BotShortLinksRedirectPage /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(lazy(() => import("../pages/authentication/Register")));
const ResetPassword = Loadable(lazy(() => import("../pages/authentication/ResetPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/authentication/VerifyCode")));
// Dashboard
const AdminPage = Loadable(lazy(() => import("../pages/dashboard/AdminHome")));
const UserListPage = Loadable(lazy(() => import("../pages/dashboard/UserListPage")));
const ContactsPage = Loadable(lazy(() => import("../pages/dashboard/ContactsPage")));
const DashboardMain = Loadable(lazy(() => import("../pages/DashboardMain")));
const CallsListPage = Loadable(
  lazy(() => import("../pages/dashboard/CallsListPage/CallsListPage"))
);
const CallsPage = Loadable(lazy(() => import("../pages/dashboard/CallsPage")));
const ImportLeadsPage = Loadable(lazy(() => import("../pages/dashboard/ImportLeadsPage")));
const WhatsAppExtensionTutorialPage = Loadable(
  lazy(() => import("../pages/dashboard/WhatsAppExtensionTutorialPage"))
);
const WhatsAppWebSendMessagesPage = Loadable(
  lazy(() => import("../pages/dashboard/WhatsAppWebSendMessagesPage"))
);
const BotShortLinksRedirectPage = Loadable(
  lazy(() => import("../pages/dashboard/BotShortLinksRedirectPage"))
);
const BotAutoReplyPage = Loadable(lazy(() => import("../pages/dashboard/BotAutoReplyPage")));
const ListingsPage = Loadable(lazy(() => import("../pages/dashboard/ListingsPage")));
const ListingsMatchPage = Loadable(lazy(() => import("../pages/dashboard/ListingsMatchPage")));
const ListingMatchToContactsPage = Loadable(
  lazy(() => import("../pages/dashboard/ListingMatchToContactsPage"))
);
const EditContactBeforeListingsMatchPage = Loadable(
  lazy(() => import("../pages/dashboard/EditContactBeforeListingsMatchPage"))
);
const ListingsMatchClientPublicPage = Loadable(
  lazy(() => import("../pages/dashboard/ListingsMatchClientPublicPage"))
);
const CustomerListings = Loadable(lazy(() => import("../pages/dashboard/CustomerListingsPage")));
const PublicListingPage = Loadable(lazy(() => import("../pages/dashboard/PublicListingPage")));
// Main
const SettingPage = Loadable(lazy(() => import("../pages/dashboard/SettingPage")));
const CalendarPage = Loadable(lazy(() => import("../pages/dashboard/CalendarPage")));
const InDevelopmentPage = Loadable(lazy(() => import("../pages/InDevelopmentPage")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
